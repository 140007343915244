.support {
    width: 100%;
    margin-top: 6rem;
    position: relative;
}

.overlay {
    width: 100%;
    height: 700px;
    background: rgba(168, 191, 230, 0.9); /* Corresponds to bg-gray-900/90 */
    position: absolute;
    top: 0;
    left: 0;
}



.content {
    max-width: 1240px;
    margin: 0 auto;
    color: white;
    position: relative;
}

.text-container {
    padding: 3rem 1rem;
    text-align: center;
}

.section-title {
    font-size: 1.875rem; /* Corresponds to text-3xl */
    padding-top: 2rem;
    color: #053983; /* Corresponds to text-slate-300 */
    text-transform: uppercase;
}
.formation {
    width: 100%  ;
    margin-bottom: 3px;
    border: #4b5563;
}

.section-subtitle {
    font-size: 3rem; /* Corresponds to text-5xl */
    font-weight: bold;
    padding: 1.5rem 0;
}

.section-text {
    font-size: 1.875rem; /* Corresponds to text-3xl */
    color: #053983; /* Corresponds to text-slate-300 */
    padding: 1rem 0;
}

.card-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem 0.5rem;
    padding: 3rem 1rem;
    color: black;
}
.card {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    max-width: 400px;
    margin: auto;
}

.card-content {
    padding: 16px;
}

.card-title {
    font-size: 1.5em;
    margin-bottom: 16px;
    color: #333;
}

.formation {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 16px;
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
}

.formation:hover {
    background-color: #f1f1f1;
}

.formation h5 {
    margin: 8px 0;
    font-size: 1.1em;
    color: #555;
}

@media (min-width: 1024px) {
    .card-grid {
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
    }
}

.card {
    background: white;
    border-radius: 0.75rem; /* Corresponds to rounded-xl */
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); /* Corresponds to shadow-2xl */
}

.card-content {
    padding: 2rem;
}

.icon {
    
}

.sales-icon {
    
}

.support-icon {
    
}

.media-icon {
    /* Custom styles for media icon */
}

.card-title {
    font-size: 2rem; /* Corresponds to text-2xl */
    font-weight: bold;
    margin: 1.5rem 0;
    color: rgb(56, 70, 197);
}

.card-text {
    font-size: 1.25rem; /* Corresponds to text-xl */
    color: #4b5563; /* Corresponds to text-gray-600 */
}

.card-footer {
    
    background: #f1f5f9; /* Corresponds to bg-slate-100 */
    padding: 1rem 2rem;
}
.section-text {
    
}

.card-footer-text {
    display: flex;
    align-items: center;
    color: #4f46e5; /* Corresponds to text-indigo-600 */
}

.arrow-icon {
    width: 1.25rem; /* Corresponds to w-5 */
    margin-left: 0.5rem;
}
.mobileprim {
    display: none;
}