.driver {
    max-width: 1040px;
    margin: auto;
    padding:  1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
}

.driver img {
    margin-top: 120px;
    max-width: 512px;
    border-radius: 20px 20px 20px 20px;
    
}
.driver img:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: 0.5s ease-in-out;
}
.pilote {
    transition: transform 0.3s ease;
    cursor: pointer;
}
.pilte:hover {
    transform: scale(1.1);
     
    
}

.driver h2 {
    padding-top: 30%;
}

.driver span {
    color: #118c4f;
}

.driver p {
    font-size: 1rem;
    color: #333;
    padding: 1.5rem 0;
}





@media screen and (max-width: 920px) {
    .driver {
        grid-template-columns: 1fr;
    }

    .driver img {
        max-width: 80%;
        margin: auto;
    }

    .driver .left {
        display: flex;
        justify-content: center;
    }

    .driver h2 {
        padding-top: 10%;
    }
}