.signup .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    padding: 3rem 1rem;
}

.signup .left {
    text-align: center;
}

.signup img {
    width: 500px;
    margin-right: 130px;
}

.signup img:hover {
    transform: scale(1.1);
    transition: 0.5s ease-in-out;
}

.signup .right p {
    margin: 2rem 0;
}

@media screen and (max-width:940px) {
    .signup .container {
        grid-template-columns: 1fr;
    }
    .signup img {
        width: 300px;
        margin-right: auto;
    }
}