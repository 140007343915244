
.featured {
    width: 100%;
    padding: 8rem 0;
    background-color: var(--background);
    clip-path: polygon(0 0, 100% 10%, 100% 100%, 0% 90%);
}

.featured .container {
    display: block;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.featured .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 2rem;
}

.featured .left p {
    margin: 1.5rem 0;
}

.featured .left .btn {
    align-self: flex-start;
}



@media screen and (max-width: 940px) {
    .featured .container {
        grid-template-columns: 1fr;
    }

    .featured .left .btn {
        align-self: center;
    }
}


@media screen and (max-width: 478px) {
    .featured .container .right .card {
        width: 100%;
    }
}