.pricing {
    width: 100%;
    margin-top: 5rem;
}

.pricing .container {
    max-width: 1240px;
    padding: 1rem;
    margin: auto;
    overflow: hidden;
}

.pricing .top-content {
    display: grid;
    grid-template-columns: 3fr 1fr;
    border-bottom: 1px solid var(--lightgrey);
    padding-bottom: 3rem;
}

.pricing .top-content h2 {
    font-size: 38px;
    padding-bottom: .5rem;
}

.pricing .top-content span {
    color: var(--primary);
}
.pricing .top-content p {
    color: var(--grey);
}

.pricing .bottom-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    margin: 3rem auto;
}

.pricing .card {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1rem 1rem 1rem 0;
}
.pilote {
    transition: transform 0.3s ease;
    cursor: pointer;
}
.pilote:hover {
    transform: scale(1.1);

}


.pricing .card div {
    display: flex;
    border-bottom: 1px solid var(--lightgrey);
    padding: 1rem 0;
}

.pricing .card .no-border {
    border: none;
}
.aservices {
    margin-left: 80px;
    margin-top: 90px;
}
.servimage {
    width: 700px;
    margin-left: 500px;
    margin-top: -500px;
}


.pricing .card span {
    color: var(--primary);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
}

.pricing .card h3 {
    font-size: 28px;
    margin: .7rem;
}

@media screen and (max-width:960px) {
    .pricing .top-content {
        grid-template-columns: 1fr;
    }

    .pricing .container .bottom-content {
        grid-template-columns: 1fr;
    }
    .pricing .top-content h2 {
        font-size: 32px;
    }
    .aservices img {
        display: inline;
    }
}