.hero .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 4rem auto;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.hero .left {
    padding: 1rem;
}

.hero h1 {
    padding: 1rem 0;
}

.hero .input-container {
    padding: 1rem 0;
}

.hero .right:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: 0.5s ease-in-out;
    width: 100%;
    
}

.hero .img-container {
    width: 100%;
   
    display: flex;
}

.hero img {
    width: 80%;
    margin: auto;
    
}

@media screen and (max-width: 940px) {
    .hero .container {
        grid-template-columns: 1fr;
        margin: 1rem 0;
    }
    .hero .right {
        display: none;
    }
}












